// Breakpoints:start
export const breakpoints = {
  small: "768",
  medium: "1024",
  large: "1200",
  extraLarge: "1440",
  wide: "2000",
}
// Breakpoints:end

// Colors:start
export const colors = {
  // Insert your variables here
  white: "#FFFFFF",
  black: "#000",
  blue: "#0061DF",
  dark: "#0D263B",
  red: "#CC5040",
  green: "#5EC6A8",
  teal: "#BDD0DD",
  grey: "#696969",
  grey__light: "#E6E9EC",
  grey__ultraLight: "#F5F7FC",
}
// Colors:end

// Font Families:start
export const fontFamilies = {
  primary: "Cerebri Sans Pro",
  secondary: "Sofia Pro",
}
// Font Families:end

// Font Weights:start
export const weights = {
  400: "400",
  500: "500",
  600: "600",
  700: "700",
}
// Font Weights:end

// Shadows:start
export const shadows = {
  primary: "0px 10px 50px rgba(13, 38, 59, 0.1)",
  secondary: "0px 4px 22px rgba(0, 97, 223, 0.35)",
}
// Shadows:end
