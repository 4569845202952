import { createGlobalStyle } from "styled-components"

// Utils
import { colors, fontFamilies } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Bootstrap
import "./bootstrap.scss"

// Helpers
import { Helpers } from "./helpers"

const GlobalStyles = createGlobalStyle`
  body {
    color: ${colors.dark};
    font-family: ${fontFamilies.primary}, sans-serif;
    font-size: 16px;
    line-height: 1.3em;

    h1,
    h2,
    h3 {
      font-weight: 600;
      line-height: 1.2em;
    }

    h1 {
      font-size: 30px;

      ${breakpoint.large`
        font-size: 60px;
      `}
    }

    h2 {
      font-size: 30px;

      ${breakpoint.large`
        font-size: 48px;
      `}
    }

    h3 {
      font-size: 26px;

      ${breakpoint.large`
        font-size: 34px;
      `}
    }

    p {

      &.paragraph--small {
        font-size: 14px;
      }
    }

    a {
      color: inherit;
      text-decoration: none;
      transition: all 0.3s ease;
    }

    img,
    svg {
      max-width: 100%;
      display: inline-block;
    }

    button {
      background: 0;
      border: 0;
      margin: 0;
      padding: 0;
    }

    form,
    input,
    button {
      font-family: inherit;
      font-size: inherit;
      color: inherit;
    }

    .fun-border {
      position: relative;
      cursor: pointer;

      &::before {
        content: '';
        width: 0;
        height: 2px;
        position: absolute;
        top: calc(100% + 8px);
        right: 0;
        left: 0;
        margin: auto; 
        background-color: ${colors.blue};
        transition: all 0.3s ease;
      }

      &:hover,
      &.active {

        &::before {
          width: 100%;
        }
      }
    }
  }

  ${Helpers}
`

export default GlobalStyles
