import React, { useState } from "react"

// Libraries
import styled, { css } from "styled-components"
import { Link } from "gatsby"

// Utils
import { colors } from "utils/variables/"
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"

// Icons
import IconLogo from "assets/icons/icon-logo.inline.svg"
import IconCaret from "assets/icons/icon-caret.inline.svg"

const StyledMenu = styled.nav`
  width: 100%;
  position: relative;
  padding: 12px 0;

  ${breakpoint.medium`
    padding: 20px 0;
  `}

  .menu__toggler {
    display: flex;
    flex-direction: column;
    cursor: pointer;

    ${breakpoint.medium`
      display: none;
    `}

    span {
      width: 20px;
      height: 3px;
      position: relative;
      margin-bottom: 3px;
      background-color: ${colors.dark};
      border-radius: 16px;
      transition: all 0.3s ease;

      &:last-child {
        margin: 0;
      }

      ${(props) =>
        props.active &&
        css`
          &:first-child {
            top: 6px;
            transform: rotate(45deg);
          }

          &:nth-child(2) {
            opacity: 0;
          }

          &:last-child {
            top: -6px;
            transform: rotate(-45deg);
          }
        `}
    }
  }

  .menu__content {
    max-height: ${(props) => (props.active ? "1000px" : "0")};
    width: 100%;
    height: calc(100vh - 100%);
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    transition: ${(props) =>
      props.active ? "all 0.3s ease-in-out" : "all 0.3s ease-in-out"};
    background-color: ${colors.white};
    overflow: hidden;
    z-index: 9999;

    ${breakpoint.medium`
      max-height: 100%;
      width: auto;
      height: auto;
      position: relative;
      top: 0;
      display: flex;
      overflow: visible;
    `}

    .menu__item {
      border-bottom: 1px solid ${colors.grey__light};

      &:first-child {
        border-top: 1px solid ${colors.grey__light};
      }

      ${breakpoint.medium`
        border: 0!important;
        margin-right: 16px;

        &:last-child {
          margin: 0;
        }
      `}

      .menu__link {
        position: relative;
        padding: 16px;

        ${breakpoint.medium`
          padding: 0;
        `}

        svg {
          ${breakpoint.medium`
            display: none;
          `}
          * {
            transition: all 0.3s ease;
          }
        }
      }
    }
  }
`

const Menu = () => {
  const [active, setActive] = useState(false)

  const menuItems = [
    {
      name: "Comprar",
      url: "/comprar",
    },
    {
      name: "Alquilar",
      url: "/alquilar",
    },
    {
      name: "Sobre Nosotros",
      url: "/sobre-nosotros",
    },
    {
      name: "Contacto",
      url: "/contacto",
    },
  ]

  return (
    <StyledMenu active={active}>
      <Container className="d-flex flex-wrap align-items-center justify-content-between">
        <Link to="/" className="d-flex align-items-center">
          <IconLogo />
        </Link>

        <button
          type="button"
          className="menu__toggler"
          onClick={() => setActive(!active)}
        >
          <span></span>
          <span></span>
          <span></span>
        </button>

        <ul className="menu__content">
          {menuItems.map((item, index) => (
            <li className="menu__item" key={index}>
              <Link
                to={item.url}
                className="menu__link fun-border d-flex align-items-center justify-content-between color-hover--blue svg-hover--fill-blue font-weight--600"
              >
                {item.name}
                <IconCaret />
              </Link>
            </li>
          ))}
        </ul>
      </Container>
    </StyledMenu>
  )
}

export default Menu
