import React from "react"

// Styles
import "assets/styles/reset.css"
import GlobalStyles from "assets/styles/globalStyles"

// Components
import SEO from "components/seo/"
import Menu from "components/menu/"
import BannerSales from "components/banner-sales/"
import Footer from "components/footer/"

const Layout = ({ children }) => (
  <main>
    <SEO />
    <GlobalStyles />
    <Menu />
    {children}
    <BannerSales />
    <Footer />
  </main>
)

export default Layout
