import React from "react"

// Libraries
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import breakpoint from "utils/breakpoints/"

// Components
import Container from "components/container/"
import SocialLinks from "components/social-links/"

// Icons
import IconLogo from "assets/icons/icon-logo.inline.svg"
import IconLocation from "assets/icons/icon-location.inline.svg"
import IconPhone from "assets/icons/icon-phone.inline.svg"
import IconEnvelope from "assets/icons/icon-envelope.inline.svg"

const StyledFooter = styled.footer`
  padding: 40px 0;

  ${breakpoint.medium`
    padding: 80px 0 40px 0;
  `}

  .footer__item {
    p {
      display: flex;

      svg {
        position: relative;
        top: 1px;
        margin-right: 12px;
      }
    }
  }

  .footer__social-links {
    display: flex;
    align-items: center;

    li {
      margin-right: 8px;

      a {
        width: 32px;
        height: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;

        &:hover {
          background-color: rgba(255, 255, 255, 0.1);
        }

        svg {
          width: auto;
          height: 20px;
        }
      }
    }
  }

  .footer__copyright {
    padding-top: 24px;
    margin-top: 40px;
    border-top: 1px solid rgba(255, 255, 255, 0.3);

    ${breakpoint.medium`
      padding-top: 32px;
      margin-top: 80px;
    `}

    p {
      opacity: 0.5;
    }
  }
`

const Footer = () => {
  return (
    <StyledFooter className="bg--dark color--white">
      <Container>
        <div className="row">
          <div className="footer__item col-12 col-sm-6">
            <p className="mb-4 font-weight--700">Contactanos</p>
            <p className="d-flex mb-3">
              <IconLocation />
              Avenida Alem 1404, Bahía Blanca. <br /> Buenos Aires, Argentina.
            </p>
            <p className="mb-3">
              <IconPhone />
              +54 291 649 5250
            </p>
            <p className="mb-4">
              <IconEnvelope />
              contacto@props.com.ar
            </p>
            <SocialLinks className="footer__social-links" />
          </div>
        </div>

        <div className="footer__copyright">
          <div className="d-flex flex-wrap flex-md-nowrap align-items-center justify-content-between">
            <Link to="/" title="Logo">
              <IconLogo className="svg--fill-white" />
            </Link>

            <p>Copyright © 2021. Todos los derechos reservados.</p>
          </div>
        </div>
      </Container>
    </StyledFooter>
  )
}

export default Footer
