import React from "react"

// Libraries
import styled from "styled-components"
import { Link } from "gatsby"

// Utils
import { colors } from "utils/variables/"

const ButtonStyles = `
  display: inline-flex;
  padding: 16px 32px;
  font-size: 13px;
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 3px;
  transition: all 0.3s ease;
`

export const ButtonPrimaryLink = styled(Link)`
  ${ButtonStyles};
`

export const ButtonLinkTertiary = styled(Link)`
  ${ButtonStyles};
  color: ${colors.blue};
  border: 1px solid ${colors.blue};

  &:hover {
    background-color: ${colors.blue};
    color: ${colors.white};
  }
`
