import React from "react"

// Icons
import IconFacebook from "assets/icons/icon-facebook.inline.svg"
import IconInstagram from "assets/icons/icon-instagram.inline.svg"
import IconLinkedIn from "assets/icons/icon-linkedin.inline.svg"

const SocialLinks = ({ className }) => {
  const links = [
    {
      title: "Facebook",
      url: "https://facebook.com",
      icon: <IconFacebook />,
    },
    {
      title: "Instagram",
      url: "https://instagram.com",
      icon: <IconInstagram />,
    },
    {
      title: "LinkedIn",
      url: "https://linkedin.com",
      icon: <IconLinkedIn />,
    },
  ]

  return (
    <ul className={className}>
      {links.map((link) => (
        <li key={link.title}>
          <a
            href={link.url}
            target="_blank"
            rel="noopener noreferrer"
            title={link.title}
          >
            {link.icon}
          </a>
        </li>
      ))}
    </ul>
  )
}

export default SocialLinks
