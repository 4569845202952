import React from "react"

// Libraries
import styled from "styled-components"

// Components
import Container from "components/container/"
import { ButtonPrimaryLink } from "components/buttons/"

// Icons
import Texture from "assets/icons/texture-white.svg"

const StyledBannerSales = styled.section`
  padding: 60px 0;
  background-size: auto 100%;
`

const BannerSales = () => {
  return (
    <StyledBannerSales
      className="bg--blue color--white text-center"
      style={{ backgroundImage: `url("${Texture}")` }}
    >
      <Container>
        <h3 className="mb-2">¿Buscás vender tu propiedad?</h3>
        <p className="mb-4">
          Tasá con nosotros y obtené la mejor cotización del mercado
        </p>
        <ButtonPrimaryLink
          to="/contact"
          className="bg--white bg-hover--dark color--blue color-hover--white"
        >
          Contactanos
        </ButtonPrimaryLink>
      </Container>
    </StyledBannerSales>
  )
}

export default BannerSales
